
import { Component, Mixins } from "vue-property-decorator";
import { Mixin } from "@/core/mixins/mixin";
// import Contactm from "@/views/Mine/Set/Contactm.vue";

@Component({
  components: {
    // Contactm,
  },
})
export default class ExternalContactmPage extends Mixins(Mixin) {
  created() {
    location.replace(location.origin + "/third/#/contactus");
  }
}
